import localeAntd from 'antd/es/locale/ko_KR'

const messages = {
  'topBar.profileMenu.signIn': '로그인',
  'topBar.profileMenu.signUp': '참가신청',
  'topBar.profileMenu.myPage': '내 정보',
  'topBar.profileMenu.logout': '로그아웃',
  'menu.mobile.home': '홈',
  'auth.signIn': '로그인',
  'auth.signUp': '참가신청',
  'auth.register': '참가신청',

  'auth.complete.success': '참가 신청이 완료되었습니다.',
  'auth.complete.failed': '참가 신청에 실패하였습니다.',

  'auth.confirm.title': '참가신청확인',
  'auth.confirm.message': '참가신청 시 입력한 이름과 메일로 참가신청 여부를 확인 할 수 있습니다.',
  'auth.confirm.placeholder.name': '이름',
  'auth.confirm.placeholder.email': '이메일',
  'auth.confirm.failed': '참가 신청 내역을 찾을 수 없습니다.',
  'auth.confirm.button': '참가 신청 확인',
  'auth.confirm.userInfo.status.green': '확인 완료',
  'auth.confirm.userInfo.status.processing': '등록 확인',
  'auth.confirm.userInfo.message': '참가 신청이 완료되었습니다.',
  'auth.confirm.userInfo.table.title': '참가 신청 내역',
  'auth.confirm.userInfo.table.name': '등록자',
  'auth.confirm.userInfo.table.email': '이메일',
  'auth.confirm.userInfo.table.registeredAt': '등록일시',
  'auth.confirm.userInfo.status': '상태',
  'auth.confirm.userInfo.success': '신청 완료',
  'auth.confirm.userInfo.pending': '처리중',

  'auth.forgotPassword.title': '비밀번호 분실',
  'auth.forgotPassword.message': '가입하신 이메일 주소로 비밀번호 재 설정 메일을 발송합니다.',
  'auth.forgotPassword.form.emailRequired': '참가신청시 입력한 이메일을 입력해 주세요',
  'auth.forgotPassword.placeholder.email': '이메일',
  'auth.forgotPassword.sendButton': '비밀번호 초기화 메일 보내기',

  'auth.information.title': '참가신청안내',
  'auth.information.button': '참가신청하기',

  'auth.login.title': '로그인',
  'auth.login.loading': '로그인 폼 불러오는 중',
  'auth.login.blocked': '로그인이 허용되어 있지 않습니다.',
  'auth.login.placeholder.email': '이메일',
  'auth.login.placeholder.password': '비밀번호',
  'auth.login.placeholder.passwordConfirm': '비밀번호확인',
  'auth.login.passwordForgotLink': '비밀번호 찾기',
  'auth.login.submit': '로그인',
  'auth.login.requiredEmail': '참가 신청시 입력한 이메일을 입력 해 주세요',
  'auth.login.requiredPassword': '비밀번호를 입력해 주세요.',
  'auth.login.naver.processing': '네이버 아이디로 로그인 중...',
  'auth.login.naver.signingin': '로그인 중...',
  'auth.login.naver.processingSub': '잠시만 기다려 주세요',
  'auth.login.code.processing': '로그인 중...',
  'auth.login.code.signingin': '로그인 중..',
  'auth.login.code.processingSub': '잠시만 기다려 주세요',
  'auth.login.code.error': '잘못된 접근입니다.',
  'auth.login.code.block': 'SMS / 링크로의 로그인은 허가되지 않습니다.',

  'auth.signup.title': '참가 신청',
  'auth.signup.login.title': '로그인 정보 입력',
  'auth.signup.additional.title': '참가자 세부 정보 입력',

  'auth.signup.message.email': '로그인 시 사용할 이메일 주소를 기입해주세요.',
  'auth.signup.message.email.1': '이메일 주소',
  'auth.signup.message.email.2': '는',
  'auth.signup.message.email.3': '로그인 아이디',
  'auth.signup.message.email.4': '로 사용됩니다.',
  'auth.signup.placeholder.email': '이메일',
  'auth.signup.placeholder.password': '비밀번호',
  'auth.signup.placeholder.passwordConfirm': '비밀번호확인',
  'auth.signup.passwordForgotLink': '비밀번호 분실',
  'auth.signup.requiredEmail': '로그인 아이디로 사용할 이메일을 입력 해 주세요',
  'auth.signup.emailExists': '이메일 주소가 이미 존재합니다.',
  'auth.signup.isRequired': '항목은 필수입니다.',

  'auth.signup.requiredPassword': '비밀번호를 입력해 주세요.',
  'auth.signup.inputValidEmail': '이메일을 정확한 형식으로 입력해 주세요',
  'auth.signup.inputValidPassword': '비밀번호는 최소 6자 이상 입니다',
  'auth.signup.passwordHelp': '비밀번호 최소 6자 이상',
  'auth.signup.confirmPasswordNotMatch': '비밀번호와 확인이 일치하지 않습니다',

  'auth.signup.additional.required': '항목을 입력해 주세요',
  'auth.signup.agree.prefix': '참가신청 시',
  'auth.signup.agree.privacy': '개인정보처리방침',
  'auth.signup.agree.suffix': '에 동의합니다.',
  'auth.signup.agree.nda': '비밀보호 유지서약',
  'auth.signup.submit': '참가신청',
  'auth.signup.loading': '불러오는 중...',
  'auth.signup.blocked': '참가등록이 허용되어 있지 않습니다.',
  'auth.authorize.required': '로그인이 필요합니다.',
  'auth.authorize.blocked': '이 페이지에 접근할 권한이 없습니다.',
  'auth.authorize.finishSignUp': '회원가입을 완료해 주세요.',

  'auth.error.userNotFound.title': '아이디가 존재하지 않습니다.',
  'auth.error.userNotFound.description': '가입하실때 입력한 이메일을 입력해 주세요.',
  'auth.error.password.title': '비밀번호가 틀렸습니다.',
  'auth.error.password.description':
    '비밀번호를 분실하셨으면 로그인 버튼 위 비밀번호 찾기 링크를 눌러 주세요..',
  'auth.error.multiSignIn.title': '다중 로그인 금지',
  'auth.error.multiSignIn.description': '다른 기기에서 로그인되어 본 기기는 로그아웃 되었습니다.',

  'page.welcome.registerButton': '참가신청',
  'page.welcome.aboutButton': '심포지엄 소개',
  'page.welcome.liveButton': '다시보기',
  'page.welcome.programButton': '프로그램',
  'page.welcome.loginButton': '로그인',

  'page.about.title': '인사말',
  'page.about.registerButton': '참가 신청 하기',
  'page.about.programButton': '프로그램 내용 보러가기',
  'page.about.empty': '인사말이 없습니다',
  'page.about.loading': '인사말을 불러오는 중...',

  'page.live.countdown.day': '일',
  'page.live.countdown.hour': '시',
  'page.live.countdown.minutes': '분',
  'page.live.countdown.seconds': '초',
  'page.live.countdown.remains': '남았습니다.',
  'page.live.countdown.message': `Live event started
  Please wait a moment`,
  'page.live.title': '라이브',
  'page.live.timeFormat': 'HH시 mm분',
  'page.live.viewMode.cinema': '크게보기',
  'page.live.viewMode.standard': '기본보기',
  'page.live.loading': '라이브 불러오는 중...',
  'page.live.blocked': '이 페이지에 접근할 권한이 없습니다.',
  'page.live.empty': '진행중인 라이브가 없습니다.',
  'page.live.playVOD': '다시보기',
  'page.live.fin': '방송이 종료되었습니다',

  'page.vod.title': 'VOD',
  'page.vod.play': '다시보기',

  'page.program.title': '프로그램',

  'page.speaker.title': '연사소개',

  'page.survey.title': '설문조사',
  'page.survey.submit': '제출하기',
  'page.survey.complete': '이미 설문조사를 완료하셨습니다',
  'page.survey.empty': '설문조사가 없습니다.',

  'page.survey.answer.yes': '예',
  'page.survey.answer.no': '아니오',
  'page.survey.answer.noRank': '순위없음',

  'page.survey.loading': '설문조사를 불러오는 중...',
  'page.survey.blocked': '설문조사에 참여할 권한이 없습니다.',

  'page.privacy.title': '개인정보 처리방침',
  'page.terms.title': '이용약관',

  'message.signinRequired': '로그인이 필요합니다',
  'message.signinRequiredPage': '이 페이지는 로그인이 필요합니다.',

  'page.sponsor.title': '후원',
  'page.guide.title': '이용안내',
  'page.gradeInformation.title': '평점 안내',

  'word.cancel': '취소',

  'chat.askInputForm': '질문을 입력하세요',
  'chat.title': '라이브 Q&A',
  'chat.send': '보내기',
  'chat.emptyMessage': '아직 Q&A 내용이 없습니다.',
  'chat.blocked': '회원님은 채팅이 허용되어 있지 않습니다.',

  'firebase.emailExists': '이메일이 이미 존재합니다.',
  'firebase.invalidEmail': '이메일 형식이 잘못되었습니다.',
  'firebase.weekPassword': '비밀번호가 취약합니다',

  'page.title.welcome': 'Home',
  'page.title.support': 'Support',
  'page.title.seminar': 'Seminar',
  'page.title.signin': 'Sign in',
  'page.title.signup': 'Sign up',
  'page.title.forgotpassword': 'Forgot password',
  'page.title.survey': 'Survey',
  'page.title.vod': 'VOD',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
