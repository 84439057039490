import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { renderDraft } from 'components/Helper'
import { FormattedMessage } from 'react-intl'
import PATH from 'path.config'

import style from './style.module.scss'

const Footer = () => {
  // const intl = useIntl()
  const settings = useSelector((state) => state.Project.settings)

  const renderFooterLogo = () => {
    if (settings.largeFooterLogoImageUrl) {
      return <img src={settings.largeFooterLogoImageUrl} alt="logo" className={style.logo} />
    }
    return null
  }
  const renderFooter = () => {
    if (settings) {
      return renderDraft(settings.pageFooter)
    }
    return null
  }

  if (!settings) {
    return null
  }

  return (
    <div className={style.footer}>
      <div className={style.links}>
        <Link to={PATH.TERMS}>
          <FormattedMessage id="page.terms.title" />
        </Link>
        <Link to={PATH.PRIVACY} className={style.privacy}>
          <FormattedMessage id="page.privacy.title" />
        </Link>
      </div>
      <div className={`${style.footerInner} `}>
        <div className={`${style.logoContainer} `}>{renderFooterLogo()}</div>
        <div className={`${style.textContainer} `}>{renderFooter()}</div>
      </div>
    </div>
  )
}

export default Footer
