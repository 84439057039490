import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import { getFirestore } from 'firebase/firestore'

import ReduxSagaFirebase from 'redux-saga-firebase'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

firebase.initializeApp(firebaseConfig)

export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()
export const fireStore = firebase.firestore()
export const fireBase = firebase
export const firebaseFunctions = firebase.functions()
export const firebaseKoreaFunctions = firebase.app().functions('asia-northeast3')
export const rsFirebase = new ReduxSagaFirebase(fireBase)
export const analytics = firebase.analytics()

// firebase 9 new
export const db = getFirestore()
